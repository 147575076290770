import {ObjectData} from "../objectData";
import {User} from "../user/user";
import {ReportParam} from "../parameters/reportParam/reportParam";
import {AvailableFilter} from "../filters/availableFilter";
import {AvailableFilterDetail} from "../filters/availableFilterDetail";
import {formatDate} from "@angular/common";

export interface SavedFilterAttributes {
  system: boolean;
  choices: {
    model_attributes?: ChoiceAttribute;
    model_associations?: ChoiceAssociatedAttribute
    model_custom_attributes?: ChoiceAttribute;
    ticket_sla?: ChoiceAssociatedAttribute;
  };
  title: string;
}

export interface ChoiceAttribute {
  [key: string]: ChoiceAttributeData;
}

export interface ChoiceAssociatedAttribute {
  [key: string]: {
    [attrKey: string]: ChoiceAttributeData;
  };
}

export interface ChoiceAttributeData {
  operators: string[];
  values: any;
  secondary_operators?: string[];
}

export interface SavedFilterRelationships {
}

const PREDEFINED_DATES: string[] = ['TODAY','YESTERDAY','THIS_WEEK','LAST_WEEK','THIS_MONTH','LAST_MONTH','THIS_QUARTER','LAST_QUARTER','THIS_YEAR','LAST_YEAR','TOMORROW'];
const PREDEFINED_X_DATES: string[] = ['X_MINUTES','X_HOURS','X_DAYS','X_BUSINESS_DAYS','X_WEEKS','X_MONTHS','X_QUARTERS','X_YEARS', 'X_DAYS_FUTURE', 'X_BUSINESS_DAYS_FUTURE', 'X_WEEKS_FUTURE', 'X_MONTHS_FUTURE', 'X_YEARS_FUTURE', 'X_MINUTES_FUTURE', 'X_HOURS_FUTURE'];

export class SavedFilter extends ObjectData<SavedFilterAttributes, SavedFilterRelationships>{
  constructor(data: ObjectData<SavedFilterAttributes, SavedFilterRelationships>, included: Object[]) {
    super(data, included);
  }

  static getChoiceAttribute(key: string, attributeData: ChoiceAttributeData ): ChoiceAttribute {
    return {
      [key]: attributeData
    }
  }

  static getChoiceAssociatedAttribute(key: string, attrKey: string, attributeData: ChoiceAttributeData ): ChoiceAssociatedAttribute {
    return {
      [key]: {
        [attrKey]: attributeData
      }
    }
  }

  static getReportParamsFromChoices(choices: any, user: User, availableFilter: AvailableFilter): ReportParam[] {
    const reportParams: ReportParam[] = [];
    if (choices.model_associations) {
      Object.keys(choices.model_associations)?.forEach((key: string) => {
        if (choices.model_associations?.[key]) {
          Object.keys(choices.model_associations[key])?.forEach((attrKey: string) => {
            if (choices.model_associations?.[key]?.[attrKey]) {
              //console.log(key, attrKey)
              const attr: ChoiceAssociatedAttribute | undefined = SavedFilter.getChoiceAssociatedAttribute(key, attrKey, choices.model_associations[key][attrKey]);
              if (attr && choices.model_associations[key][attrKey].operators?.length) {
                const reportParam: ReportParam = SavedFilter.getReportParamBySavedFilterChoiceAssociationAttribute(attr, user, availableFilter);
                reportParams.push(reportParam);
              }
            }
          });
        }
      });
    }

    if (choices.model_attributes && Object.keys(choices.model_attributes)?.length) {
      Object.keys(choices.model_attributes)?.forEach((key: string) => {
        if (choices.model_attributes?.[key]) {
          //console.log(key)
          const attr: ChoiceAttribute | undefined = SavedFilter.getChoiceAttribute(key, choices.model_attributes[key]);
          if (attr && choices.model_attributes[key].operators?.length) {
            const reportParam: ReportParam = SavedFilter.getReportParamBySavedFilterChoiceAttribute(attr, user, availableFilter);
            if(reportParam.value) {
              reportParams.push(reportParam);
            }
          }
        }
      });
    }

    if (choices.ticket_sla) {
      Object.keys(choices.ticket_sla)?.forEach((key: string) => {
        if (choices.ticket_sla?.[key]) {
          Object.keys(choices.ticket_sla[key])?.forEach((attrKey: string) => {
            //console.log(key, attrKey)
            if (choices.ticket_sla?.[key]?.[attrKey]) {
              const attr: ChoiceAssociatedAttribute = SavedFilter.getChoiceAssociatedAttribute(key, attrKey, choices.ticket_sla[key][attrKey]);
              if (attr && choices.ticket_sla[key][attrKey].operators?.length) {
                const reportParam: ReportParam = SavedFilter.getReportParamBySavedFilterChoiceTicketSLA(attr, user, availableFilter);
                reportParams.push(reportParam);
              }
            }
          });
        }
      });
    }

    return reportParams;
  }

  static getCustomValuesFromChoices(choices: any, user: User, availableFilter: AvailableFilter): ReportParam[] {
    const customValues: ReportParam[] = [];
    if (choices.model_custom_attributes) {
      Object.keys(choices.model_custom_attributes)?.forEach((key: string) => {
        if (choices.model_custom_attributes?.[key]) {
          //console.log(key)
          const attr: ChoiceAttribute | undefined = SavedFilter.getChoiceAttribute(key, choices.model_custom_attributes[key]);
          if (attr && choices.model_custom_attributes[key].operators?.length) {
            const reportParam: ReportParam = SavedFilter.getReportParamBySavedFilterChoiceAttribute(attr, user, availableFilter);
            customValues.push(reportParam);
          }
        }
      });
    }

    return customValues;
  }

  static getReportParamBySavedFilterChoiceAttribute(choice: ChoiceAttribute, user: User, availableFilter: AvailableFilter): ReportParam {
    const key = Object.keys(choice)[0];
    const operator = SavedFilter.getOperatorWhenEmpty(choice[key], undefined);
    const value = SavedFilter.getValueByOperator(choice[key], undefined, user, availableFilter.filters[key]);
    //console.log(key, operator, value);

    return new ReportParam(key, operator, value);
  }

  static getReportParamBySavedFilterChoiceAssociationAttribute(choice: ChoiceAssociatedAttribute, user: User, availableFilter: AvailableFilter): ReportParam {
    const key = Object.keys(choice)[0];
    const attrKey = Object.keys(choice[key])[0];
    const computedKey: string = `${key}.${attrKey}`;
    const operator = SavedFilter.getOperatorWhenEmpty(choice[key], attrKey);
    const value = SavedFilter.getValueByOperator(choice[key], attrKey, user, availableFilter?.filters[key+'.'+attrKey]);

    return new ReportParam(computedKey, operator, value);
  }

  static getReportParamBySavedFilterChoiceTicketSLA(choice: ChoiceAssociatedAttribute, user: User, availableFilter: AvailableFilter): ReportParam {
    const key = Object.keys(choice)[0];
    const attrKey = Object.keys(choice[key])[0];
    const computedKey: string = `${key}.${attrKey}`;
    const operator = SavedFilter.getOperatorWhenEmpty(choice[key], attrKey);
    const value = SavedFilter.getValueByOperator(choice[key], attrKey, user, availableFilter?.filters[attrKey]);

    return new ReportParam(computedKey, operator, value);
  }

  static getOperatorWhenEmpty(choice: any, attr: string | undefined): string[] {
    // if (Array.isArray(values) && values.length > 0) {
    //   return values[0]?.id != '_' ? [operator] : ['empty'];
    // }
    const operators = attr ? choice[attr].operators : choice.operators;
    if (operators[0] == 'predefined_range') {
      return attr ? choice[attr].secondary_operators : choice.secondary_operators;
    }

    return operators;
  }

  static getValueByOperator(choice: any, attr: string | undefined, user: User, availableFilterDetail: AvailableFilterDetail | undefined) {
    const values = attr ? choice[attr].values : choice.values;
    const operators = attr ? choice[attr].operators : choice.operators;
    let normalizedValues: any;
    let operator = '';
    if (Array.isArray(operators) && operators.length > 1) {
      normalizedValues = values.map((value: any) => value.id);

      return normalizedValues;
    } else {
      operator = operators[0];
    }

    //console.log(choice, attr, user)
    //console.log(choice, availableFilterDetail)
    switch (operator) {
      case 'not_in':
      case 'in':
        normalizedValues = values.map((val: any) => {
          if (val.id == '_') {
            return user.id
          }

          return val.id;
        });

        break;
      case 'eq':
        if (availableFilterDetail?.type == 'date') {
          normalizedValues = SavedFilter.getDateValue(values);
        } else {
          if (Array.isArray(values)) {
            normalizedValues = values[0].id.toString();
          } else {
            normalizedValues = values.id.toString();
          }
        }
        break;
      case 'like':
        if (Array.isArray(values)) {
          normalizedValues = values[0].id.toString();
        } else {
          normalizedValues = values.id.toString();
        }
        break;
      case 'gt':
      case 'gte':
      case 'lt':
      case 'lte':
        if (availableFilterDetail?.type == 'date') {
          normalizedValues = SavedFilter.getDateValue(values);
        }else {
          normalizedValues = Array.isArray(values) ? values.map((val: any) => val.id) : values.id;
        }
        break;
      case 'empty':
        normalizedValues = '0';
        break;
      case 'predefined_range':
        normalizedValues = values;
        break;
      default:
        normalizedValues = values.id.toString();
        break;
    }

    return normalizedValues;
  }

  static getDateValue(values: any) {
    let isPredefined = false;
    let isXDate = false;

    if(Array.isArray(values)) {
      values.forEach((val: any) => {
        if (PREDEFINED_DATES.includes(val.id)) {
          isPredefined = true;
        }
      });
    } else {
      if (values.id && Array.isArray(values.id)) {
        values.id.forEach((val: any) => {
          if (PREDEFINED_X_DATES.includes(val)) {
            isXDate = true;
          }
        })
      }
    }
    if (isPredefined) {
      return values.map((val: any) => val.id);
    }
    if (isXDate) {
      return SavedFilter.handleXDays(values);
    }

    return values.map((val: any) => {
      return formatDate(val.id, 'yyyy-MM-dd', 'en-US')
    });
  }

  static handleXDays(values: any) {
    //expected value ['X_DAYS', 30]
    return values.id;
  }
}
