import { Component, DestroyRef, OnInit } from '@angular/core';
import { UsersService } from "src/app/services/users.service";
import { TicketsService } from "src/app/services/tickets.service";
import { Ticket } from "src/app/utilities/models/ticket/ticket";
import { ReportParam } from "src/app/utilities/models/parameters/reportParam/reportParam";
import {BehaviorSubject, distinctUntilChanged, skip, Subject, switchMap} from "rxjs";
import { NzMessageService } from "ng-zorro-antd/message";
import { debounceTime } from "rxjs/operators";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {SavedFiltersService} from "src/app/services/savedFiltersService";
import {
  SavedFilter
} from "src/app/utilities/models/savedFilter/savedFilter";
import {FiltersService} from "src/app/services/filters.service";
import {AvailableFiltersResponse} from "../../../../utilities/models/filters/availableFiltersResponse";
import {AvailableFilter} from "../../../../utilities/models/filters/availableFilter";

@Component({
    selector: 'ticketList',
    host: { 'class': 'tickets-list-wrapper' },
    templateUrl: './ticketList.component.html',
    styleUrls: ['./ticketList.component.scss'],
    standalone: false
})
export class TicketListComponent implements OnInit {
  selectedSavedFilter: SavedFilter;
  previousSelectedSavedFilter: SavedFilter;
  tickets: Ticket[] = [];
  savedFilters: SavedFilter[] = [];
  savedFiltersCount: {[key: number]: number} = {};
  loading = false;
  filters: ReportParam[];
  pageIndex: number = 1;
  pageSize: number = 20;
  total: number;
  totalPages: number;
  searchString: string = '';
  searchSubject$ = new BehaviorSubject<string>('');
  private availableFilter: AvailableFilter;
  private availableFiltersSet$ = new Subject<boolean>;
  private savedFilterListSet$ = new Subject<boolean>;

  constructor(private userService: UsersService,
              private ticketService: TicketsService,
              private filtersService: FiltersService,
              private savedFilterService: SavedFiltersService,
              private msg: NzMessageService,
              private destroyRef: DestroyRef) {
  }

  ngOnInit() {
    this.previousSelectedSavedFilter = this.ticketService.previousSelectedSavedFilter;
    this.getAvailableFilters();
    this.setSearchSubject();

    this.availableFiltersSet$.subscribe({
      next: (value: boolean) => {
        if (value) {
          this.getSavedFiltersList();
        }
      }
    })
    this.savedFilterListSet$.subscribe({
      next: (value: boolean) => {
        if (value) {
          this.getSavedFiltersCount();
          this.sortSavedFilters();
          this.applyDefaultSavedFilter();
        }
      }
    })
  }

  onPageIndexChange(indexNumber: number) {
    this.pageIndex = indexNumber;
    this.applySavedFilter(this.previousSelectedSavedFilter, this.pageIndex, this.pageSize);
  }

  onPageSizeChange(sizeNumber: number) {
    this.pageIndex = 1
    this.pageSize = sizeNumber;
    this.applySavedFilter(this.previousSelectedSavedFilter, this.pageIndex, this.pageSize);
  }

  searchTickets() {
    if(this.searchString.length == 0 || this.searchString.length > 2) {
      this.searchSubject$.next(this.searchString);
    }
  }

  applySavedFilter(savedFilter: SavedFilter, pageIndex?: number, pageSize?: number) {
    const choices = savedFilter.attributes.choices;
    //console.log(choices)
    const reportParams: ReportParam[] = SavedFilter.getReportParamsFromChoices(choices, this.userService.loggedInUser, this.availableFilter);
    const customValues: ReportParam[] = SavedFilter.getCustomValuesFromChoices(choices, this.userService.loggedInUser, this.availableFilter);

    //console.log(reportParams, customValues);
    this.loading = true;
    this.ticketService.getTickets({reportParams: reportParams, customValues: customValues, pageSize: pageSize || this.pageSize, pageIndex: pageIndex || this.pageIndex || 1})
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.tickets = [];
          this.pageIndex = response.meta.current_page;
          this.total = response.meta.total_count;
          this.totalPages = response.meta.total_pages;
          response.data.forEach((ticket: any) => {
            let tmpTicket = new Ticket(ticket, response.included);
            tmpTicket.relationships!.status!.attributes!.status_data = { background_color: this.userService.statusArray.find(status => status.id == tmpTicket.relationships?.status?.id)?.background_color || 'Black' }
            this.tickets.push(tmpTicket);
          });
          this.loading = false;
          this.ticketService.setPreviousSelectedSavedFilter(savedFilter);
          this.previousSelectedSavedFilter = savedFilter;
        },
        error: err => {
          this.msg.error('An error occurred!', { nzDuration: 3000, nzPauseOnHover: false });
          console.log(err);
          this.loading = false;
        }
      })
  }

  private sortSavedFilters() {
    const sortedSavedFilters: SavedFilter[] = [];
    const seenIds = new Set<number>();

    const positions = this.userService.loggedInUser.attributes.pref_saved_filters_position?.split(',').map(Number) || [];
    positions.forEach((id) => {
      const savedFilter = this.savedFilters?.find((item: SavedFilter) => item.id == id);
      if (savedFilter && !seenIds.has(savedFilter.id)) {
        sortedSavedFilters.push(savedFilter);
        seenIds.add(savedFilter.id);
      }
    });

    this.savedFilters?.forEach((savedFilter) => {
      if (!seenIds.has(savedFilter.id)) {
        sortedSavedFilters.push(savedFilter);
        seenIds.add(savedFilter.id);
      }
    });

    this.savedFilters = sortedSavedFilters;
  }

  private setSearchSubject() {
    this.tickets = [];

    this.searchSubject$
      .pipe(
        debounceTime(500),
        skip(1), // this is to avoid the initial double call from init method
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
        switchMap(() => {
          this.loading = true;
          const query = { q: this.searchSubject$.getValue(), searchFields: ['code','subject','stripped_description'] };

          return this.ticketService.getTickets({
            pageIndex: 1,
            pageSize: this.pageSize,
            query,
            reportParams: this.filters,
          })
        })
      )
      .subscribe({
        next: (response: any) => {
          if(response.meta) {
            this.pageIndex = response.meta.current_page;
            this.total = response.meta.total_count;
            this.totalPages = response.meta.total_pages;
          }
          this.tickets = response.data.map((ticket: any) => {
            let tmpTicket = new Ticket(ticket, response.included);
            tmpTicket.relationships!.status!.attributes!.status_data = { background_color: this.userService.statusArray.find(status => status.id == tmpTicket.relationships?.status?.id)?.background_color || 'Black' }

            return tmpTicket;
          });
          this.loading = false;
        },
        error: err => {
          this.msg.error('An error occurred!', { nzDuration: 3000, nzPauseOnHover: false });
          console.log(err);
          this.loading = false;
        }
      })
  }

  private getSavedFiltersList() {
    this.savedFilterService.getSavedFiltersMyList()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.loading = false;
          this.savedFilters = response.data.map((savedFilter: any) => new SavedFilter(savedFilter, []));
          this.savedFilterListSet$.next(true);
        },
        error: () => {
          this.loading = false;
        }
      })
  }

  private getSavedFiltersCount() {
    this.savedFilterService.getSavedFiltersCount()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.loading = false;
          this.savedFiltersCount = response.data[0].attributes;
        },
        error: () => {
          this.loading = false;
        }
      })
  }

  private applyDefaultSavedFilter() {
    if (this.previousSelectedSavedFilter?.id) {
      const selectedSavedFilter = this.savedFilters.find( savedFilter => savedFilter.id == this.previousSelectedSavedFilter.id);
      if (selectedSavedFilter) {
        this.selectedSavedFilter = selectedSavedFilter;
        this.applySavedFilter(selectedSavedFilter);
      }
    } else {
      let defaultSavedFilter = this.savedFilters[0];
      if (this.userService.loggedInUser.attributes.pref_deskware_view) {
        const prefDefaultSavedFilter = this.savedFilters.find(savedFilter => savedFilter.id.toString() == this.userService.loggedInUser.attributes.pref_deskware_view?.toString());
        if (prefDefaultSavedFilter) {
          defaultSavedFilter = prefDefaultSavedFilter;
        }
      }
      this.selectedSavedFilter = defaultSavedFilter;

      this.applySavedFilter(defaultSavedFilter);
    }
  }

  private getAvailableFilters() {
    this.filtersService.requestAvailableFilters('tickets', 'index')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: AvailableFiltersResponse) => {
          this.availableFilter = new AvailableFilter(response, 'ticket_list');
          this.availableFiltersSet$.next(true);
        },
        error: () => {}
      })
  }
}
