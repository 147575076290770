import { Component, DestroyRef, OnInit } from '@angular/core';
import { FlowsService } from "src/app/services/flows.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Flow } from 'src/app/utilities/models/ticket/flow/flow';
import { FlowCategory } from 'src/app/utilities/models/ticket/flowCategory/flowCategory';
import { FlowCategoryWithChildren } from 'src/app/utilities/models/ticket/flowCategory/flowCategoryWithChildren';
import {TicketsService} from "src/app/services/tickets.service";
import {Router} from "@angular/router";
import {UsersService} from "src/app/services/users.service";
import {SavedFilter} from "src/app/utilities/models/savedFilter/savedFilter";
import {SavedFiltersService} from "src/app/services/savedFiltersService";

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss'],
    standalone: false
})
export class SupportComponent implements OnInit {
  searchString: string = '';
  flows: Flow[] = [];
  flowCategories: FlowCategory[];
  flowCategoriesLoading: boolean = false;
  filteredFlows: Flow[] = [];
  filteredItems: any[];
  visibleExpandedCategory: number | undefined = undefined;
  categoryTree: FlowCategoryWithChildren[] = [];
  nestingLevels: number = 1;
  loading: boolean = false;
  savedFilters: SavedFilter[] = [];
  savedFiltersCount: {[key: number]: number} = {};

  constructor(private flowService: FlowsService,
              private savedFilterService: SavedFiltersService,
              private ticketService: TicketsService,
              private userService: UsersService,
              private router: Router,
              private destroyRef: DestroyRef) { }

  ngOnInit(): void {
    this.getFlowCategories();
    this.getSavedFiltersList();
  }

  get isContact() {
    return this.userService.loggedInUser.attributes.type == 'Digcore::Contact';
  }

  get isAccountAgent() {
    return this.userService.loggedInUser.attributes.type == 'Digcore::AccountAgent';
  }

  filterSectionsAndItems() {
    this.filteredFlows = this.flows.filter((flow: Flow) => {
      return flow.attributes.title.toLowerCase().includes(this.searchString.toLowerCase()) ||
        flow.relationships?.flow_categories?.some(flowCategory => flowCategory.attributes?.title.toLowerCase().includes(this.searchString.toLowerCase()))
    });

    //add other when empty result
    if(!this.filteredFlows.find((flow: Flow) => flow.attributes.title == 'other')){
      const flowOther = this.flows.find( (flow: Flow) => flow.attributes.title.toLowerCase() == 'other' );
      if(flowOther) {
        this.filteredFlows.push(flowOther);
      }
    }
  }

  private getFlowCategories() {
    this.flowCategoriesLoading = true;
    this.flowService.getFlowCategoriesWithFlows()
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe({
      next:(response: any) => {
        this.flowCategories = response.data.map( (row: any) => {
          const ancestryPathNesting = row.attributes.ancestry?.split('/')?.length + 1 || 1;
          if(ancestryPathNesting > this.nestingLevels) this.nestingLevels = ancestryPathNesting;

          if(row.relationships?.flows?.data?.length){
            const flows = row.relationships.flows.data.map((flow: any) => {
              const includedObj = response.included.find((item: any) => item.type == flow.type && item.id == flow.id)
              const flowData = {
                ...includedObj,
                relationships: {
                  flow_categories: [
                    {
                      ...row
                    }
                  ]
                }
              }

              return new Flow(flowData, response.included)
            });

            this.flows = [
              ...this.flows,
              ...flows
            ]
          }

          return new FlowCategory(row, response.included);
        });
        this.setupCategoryTree();
        this.flowCategoriesLoading = true;
      },
      error: (error) => {
        console.log(error);
        this.flowCategoriesLoading = false;
      }
    });
  }

  /* private getNavFilterAggs() {
    if (!this.isContact) return;

    this.navFilterAggsLoading = true;
    this.ticketService.getNavFilterAggs()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.navFilterAggs = new NavFilterAggs(response, [])
          this.navFilterAggsLoading = false;
        },
        error: () => {
          this.navFilterAggsLoading = false;
          },
        complete: () => {},
      })
  } */

  showCategory(categoryId: number | undefined, key: string | undefined) {
    if (this.visibleExpandedCategory == categoryId) {
      this.visibleExpandedCategory = undefined;

      return;
    }
    this.visibleExpandedCategory = categoryId;

    if(key) {
      const elem = document.querySelector('#'+key);
      if (elem != null) {
        setTimeout(() => {
          elem.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 10)
      }
    }
  }

  private setupCategoryTree() {
    this.categoryTree = this.flowCategories.filter((flowCategory: FlowCategory) => flowCategory.attributes.parent_id == null)
      .map((flowCategory: FlowCategory) => {
        return {
          id: flowCategory.id,
          category: flowCategory,
          children: this.getFlowCategoryChildren(flowCategory.id)
        }
      }) ?? [];
  }

  private getFlowCategoryChildren(parentId: number): FlowCategoryWithChildren[] {
    return this.flowCategories.filter((flowCategory: FlowCategory) => flowCategory.attributes.parent_id == parentId).map((flowCategory: FlowCategory) => {
      return {
        id: flowCategory.id,
        category: flowCategory,
        children: this.getFlowCategoryChildren(flowCategory.id)
      }
    }) ?? []
  }

  goToTicketsList(savedFilter: SavedFilter) {
    //this.ticketService.setPreviousSelectedSavedFilter();
    this.ticketService.setPreviousSelectedSavedFilter(savedFilter);
    this.router.navigate(['app','support','tickets']);
  }

  private getSavedFiltersList() {
    this.savedFilterService.getSavedFiltersMyList()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.loading = false;
          this.savedFilters = response.data.map((savedFilter: any) => new SavedFilter(savedFilter, []));
          this.getSavedFiltersCount();
          this.sortSavedFilters();
        },
        error: () => {
          this.loading = false;
        }
      })
  }

  private getSavedFiltersCount() {
    this.savedFilterService.getSavedFiltersCount()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.loading = false;
          this.savedFiltersCount = response.data[0].attributes;
        },
        error: () => {
          this.loading = false;
        }
      })
  }

  private sortSavedFilters() {
    const sortedSavedFilters: SavedFilter[] = [];
    const positions = this.userService.loggedInUser.attributes.pref_saved_filters_position?.split(',') || [];
    positions.forEach((id: string) => {
      let savedFilter = this.savedFilters?.find((item: SavedFilter) => item.id == parseInt(id));
      if (savedFilter) {
        sortedSavedFilters.push(savedFilter);
      }
    })
    this.savedFilters = [...sortedSavedFilters, ...this.savedFilters];
  }
}
