<div class="add-service-modal-content" *ngIf="nzModalData && service && serviceMetric">
  <div class="add-service-title">Remove Request</div>
  <div class="add-service-subtitle">{{ serviceMetric.getTitle() }}</div>
  <form [formGroup]="form">
    <div nz-row>
      <div nz-col [nzSpan]="24">
        <div class="service-change-label">Changes</div>
        <div class="service-change">
          <div class="service-change-logo"><img [src]="service.getLogo()" [alt]="service.getTitle()"/></div>
          <div class="service-change-title">{{ service.getTitle() }}</div>
          <div class="service-change-metric-title">{{ serviceMetric.getTitle() }}:</div>
          <div class="service-change-metric-value">-{{ nzModalData.rows.length }}</div>
        </div>
        <nz-form-item class="ticket-create-form-item">
          <nz-form-label nzFor="effective_date" class="service-change-label" nzRequired>
            Effective Date
          </nz-form-label>
          <nz-form-control>
            <nz-date-picker
                formControlName="effective_date"
                nzFormat="MM-dd-yyyy"
                [nzDisabledDate]="disabledDate"
            ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>

        <ng-container *ngIf="managedUsersService">
          <nz-form-item class="ticket-create-form-item" *ngIf="service.id == managedUsersService.id">
            <nz-form-label nzFor="remove_all_licenses" class="service-change-label checkbox-label">
              <label nz-checkbox formControlName="remove_all_licenses">Remove all associated licenses and subscriptions for removed users</label>
            </nz-form-label>
          </nz-form-item>
        </ng-container>

        <ng-container *ngIf="saasProtectionService">
          <nz-form-item class="ticket-create-form-item" *ngIf="service.id == saasProtectionService.id">
            <nz-form-label nzFor="confirm_removal" class="service-change-label checkbox-label" nzRequired>
              <span nz-checkbox formControlName="confirm_removal">I agree that by removing user(s) from SaaS protection, the historic backups for the selected user(s) will be permanently deleted.</span>
            </nz-form-label>
          </nz-form-item>
        </ng-container>

        <nz-form-item class="ticket-create-form-item">
          <nz-form-label nzFor="additional_information" class="service-change-label">
            Additional Information
          </nz-form-label>
          <nz-form-control>
            <!-- Wysiwyg control -->
            <textarea rows="4" nz-input placeholder="Type in any additional information required" formControlName="additional_information" [froalaEditor]="froalaEditorOptions"></textarea>
          </nz-form-control>
        </nz-form-item>

        <ng-container *ngIf="empistEdrService">
          <nz-form-item class="ticket-create-form-item" *ngIf="service.id == empistEdrService.id">
            <nz-form-label nzFor="additional_action" class="remove-endpoint-label" [nzNoColon]="true">
              <span nz-checkbox formControlName="additional_action">I would also like you to delete the selected endpoint(s) from management as the endpoint(s) have been decommissioned.</span>
            </nz-form-label>
          </nz-form-item>
        </ng-container>

        <ng-container *ngIf="managedEndpointsService">
        <nz-form-item class="ticket-create-form-item" *ngIf="service.id == managedEndpointsService.id">
          <nz-form-label nzFor="additional_action" class="service-change-label checkbox-label">
            <span nz-checkbox formControlName="additional_action">Please remove all related licenses and subscription from the endpoint(s) because the endpoint(s) have been decommissioned.</span>
          </nz-form-label>
        </nz-form-item>
        </ng-container>

        <div class="note">
          <strong>Please note: </strong> <i>Removal may take a few days to be reflected in EMPIST 360 but rest assured, the change will reflect the effective date you selected above.</i>
        </div>

        <ng-container *ngIf="empistEdrService && managedEndpointsService">
          <nz-form-item class="ticket-create-form-item" *ngIf="[empistEdrService.id, managedEndpointsService.id].includes(service.id)">
            <nz-form-label class="understand-reconnect-label" [nzNoColon]="true" [nzRequired]="true">
              <span nz-checkbox formControlName="understand_reconnect">I understand that if an endpoint is reconnected to the network, it will be considered Managed and software may be deployed automatically.</span>
            </nz-form-label>
          </nz-form-item>
        </ng-container>

        <nz-form-item>
          <nz-form-control class="add-service-form-submit">
            <button nz-button type="submit" nzType="default" nzShape="round" nzSize="large" [nzLoading]="submittingRequest" (click)="closeModal()">Cancel</button>
            <button nz-button type="submit" nzShape="round" nzType="primary" nzSize="large" [nzLoading]="submittingRequest" (click)="onSubmit()" [disabled]="!form.valid">Send Request</button>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</div>
<custom-loader *ngIf="loaderVisible"></custom-loader>
