@if (user) {
  <span class="primary-email">
    <input nz-input [(ngModel)]="primaryEmail" />
    <span class="placeholder">{{primaryEmail}}</span>
  </span>

  @for (email of extraEmails; track email.attributes.email) {
    <nz-input-group nzCompact class="edit-extra-email-group">
      <button nz-button
              nzSize="small"
              nzType="link"
              [disabled]="!(hasSetPrimaryPermission)"
              type="button"
              nz-tooltip
              [nzTooltipTitle]="!!(hasSetPrimaryPermission) ? 'Set primary email' : 'Cannot set primary email!'"
              (click)="onSetPrimaryEmail(email.id)">
        <span nz-icon nzType="check-square"></span>
      </button>
      <input type="text"
             nz-input
             [(ngModel)]="email.attributes.email"
             [disabled]="!(hasUpdatePermission)"
             (keyup.enter)="onUpdateExtraEmail(email)"/>
      <button nz-button
              nzSize="small"
              nzType="text"
              [disabled]="!(hasDeletePermission)"
              type="button"
              (click)="onDeleteExtraEmail(email.id)">
        <span nz-icon nzType="delete"></span>
      </button>
      <span class="placeholder">{{email.attributes.email}}</span>
    </nz-input-group>
  }

  @if (hasCreatePermission) {
    @if (isAddingEmail) {
      <nz-input-group nzCompact class="create-extra-email-group">
        <input type="text" nz-input [(ngModel)]="newEmail" />
        <button nz-button
                nzSize="small"
                nzType="text"
                type="button"
                (click)="onSaveNewEmail()">
          <span nz-icon nzType="save"></span>
        </button>
        <button nz-button
                nzSize="small"
                nzType="text"
                type="button"
                (click)="onCancelNewEmail()">
          <span nz-icon nzType="close"></span>
        </button>
        <span class="placeholder">{{newEmail}}</span>
      </nz-input-group>
    } @else {
      <button nz-button
              nzSize="small"
              nzType="text"
              class="btn-add-email"
              type="button"
              (click)="isAddingEmail = true">
        <span nz-icon nzType="plus"></span>
      </button>
    }
  }
}
