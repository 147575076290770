import {
  AvailableFiltersResponse,
  ModelAssociation,
  ModelAssociationAttribute,
  ModelAttribute,
  ModelCustomAttribute, TicketSLA,
  TicketSLAAttribute
} from "./availableFiltersResponse";
import { AvailableFilterDetail } from "./availableFilterDetail";
import { FiltersInterface } from "./FiltersInterface";
import { FilterDropdownOption } from "./filterDropdownOption";

export class AvailableFilter {
  page: string;

  filters: FiltersInterface = {};

  constructor(response: AvailableFiltersResponse, page: string) {
    this.page = page;
    this.setFilters(response);
  }

  private setFilters(response: AvailableFiltersResponse) {
    if (!response.data) return;

    response.data[0].attributes.model_associations?.forEach((modelAssociation: ModelAssociation) => {
      modelAssociation.attributes.forEach((modelAssociationAttribute: ModelAssociationAttribute) => {
        const associationData = { name: modelAssociation.name, label: modelAssociation.label }
        const filter = this.getFilterFromModelAssociationAttributes(modelAssociationAttribute, associationData);
        if (filter) this.filters[filter.computed_key] = filter;
      })
    })
    response.data[0].attributes.model_attributes?.forEach((attribute: ModelAttribute) => {
      const filter = this.getFilterFromModelAttributes(attribute);
      if (filter) this.filters[filter.computed_key] = filter;
    })
    response.data[0].attributes.model_custom_attributes?.forEach((attribute: ModelCustomAttribute) => {
      const filter = this.getFilterFromModelCustomAttributes(attribute);
      if (filter) this.filters[filter.computed_key] = filter;
    })
    response.data[0].attributes.ticket_sla?.forEach((ticketSLA: TicketSLA) => {
      ticketSLA.attributes.forEach((attribute: TicketSLAAttribute) => {
        const filter = this.getFilterFromModelTicketSLA(attribute);
        if (filter) this.filters[filter.computed_key] = filter;
      });
    })
  }

  getFilters() {
    return this.filters;
  }

  getFiltersOptions(): FilterDropdownOption[] {
    return Object.entries(this.filters).map(([key, value]) => {
      return {
        key: key,
        label: value['computed_label']
      }
    })
  }

  getFilterDetailByKey(key: string): AvailableFilterDetail {
    return this.filters[key];
  }

  getFilterFromModelAssociationAttributes(attribute: ModelAssociationAttribute, associationData: any) {
    if (!attribute.available_as_filter?.includes(this.page)) return null;

    return new AvailableFilterDetail(attribute, 'model_association_attributes', associationData);
  }

  getFilterFromModelAttributes(attribute: ModelAttribute) {
    if (!attribute.available_as_filter?.includes(this.page)) return null;

    return new AvailableFilterDetail(attribute, 'model_attributes');
  }

  getFilterFromModelCustomAttributes(attribute: ModelCustomAttribute) {
    if (!attribute.available_as_filter?.includes(this.page)) return null;

    return new AvailableFilterDetail(attribute, 'model_custom_attributes');
  }

  getFilterFromModelTicketSLA(attribute: TicketSLAAttribute) {
    if (!attribute.available_as_filter?.includes(this.page)) return null;

    return new AvailableFilterDetail(attribute, 'ticket_sla');
  }
}
