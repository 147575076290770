import { ObjectData } from "../objectData";
import { ServiceMetricAttributes } from "./serviceMetricAttributes";
import { ServiceMetricRelationships } from "./serviceMetricRelationships";
import { ServiceMetricDetailExplanationItem } from "./serviceMetricDetailsExplanation";
import { ColumnItem, DataItem } from "./serviceData";
import { ServiceMetricRequestExplanationItem } from "./serviceMetricRequestExplanation";

class ServiceMetric extends ObjectData<ServiceMetricAttributes, ServiceMetricRelationships>{
  constructor(data: ObjectData<ServiceMetricAttributes, ServiceMetricRelationships>, included: Object[]) {
    super(data, included);
    this.addCustomAttribute(data);
  }

  addCustomAttribute(data: ObjectData<ServiceMetricAttributes, ServiceMetricRelationships>): void {
    let details_explanation: ServiceMetricDetailExplanationItem[] = [];
    let request_explanation: ServiceMetricRequestExplanationItem[] = [];
    if(typeof data.attributes.details_explanation == 'string' && data.attributes.details_explanation != ''){
      details_explanation = JSON.parse(data.attributes.details_explanation);
    }else{
      details_explanation = [...(this.attributes.details_explanation ?? [])];
    }
    if(typeof data.attributes.request_explanation == 'string' && data.attributes.request_explanation != ''){
      request_explanation = JSON.parse(data.attributes.request_explanation);
    }else{
      request_explanation = [...(this.attributes.request_explanation ?? [])];
    }
    this.attributes.details_explanation = [...details_explanation];
    this.attributes.request_explanation = [...request_explanation];
    if(this.attributes.title == 'VMware vCenter: Datastores' && !details_explanation.find(item => item.key == 'used_space')) {
      const item: ServiceMetricDetailExplanationItem = { column: 'col8', key: 'used_space', label: 'Used Space', type: 'size_in_bytes' };
      this.attributes.details_explanation = [
        ...details_explanation,
        ...request_explanation,
        item
      ];
    }
  }

  getColumnAndKeys(): { columns: ColumnItem[], keys: string[] }  {
    const keysToRemove = ['vm','power_state','free_space','capacity'];
    const d: any = this.attributes.details_explanation;
    const keys = d ? d.filter((column: any) => !keysToRemove.includes(column.key?.toLowerCase()) && !column.hide).map((row: any) => row.column) : [];
    if(this.attributes.title.toLowerCase().includes('datastore')){
      // not sure if the following line is useful anymore, but it breaks the table
      // since the Created At column is added in the template later, the error is now easy to see

      // keys.push('col8');
    }
    const columns = d ? d.filter((column: any) => !keysToRemove.includes(column.key?.toLowerCase()) && !column.hide).map((row: any) => {
      return {
        title: row.label,
        sortFn: (a: DataItem, b: DataItem) => {
          return (isNaN(a['attributes'][row.column]) || isNaN(b['attributes'][row.column]))
            ? ('' + a['attributes'][row.column]).localeCompare('' + b['attributes'][row.column])
            : a['attributes'][row.column] - b['attributes'][row.column]
        },
        sortOrder: null
      }
    }) : [];
    //console.log(keys, columns, this)

    return { keys: keys, columns: columns };
  }

  getAttributeByCol(column: string) {
    const detailExplanation: any = this.attributes.details_explanation;

    return detailExplanation.find((f: ServiceMetricDetailExplanationItem) => f.column == column);
  }

  getColumnByKey(key: string) {
    const detailExplanation: any = this.attributes.details_explanation;

    return detailExplanation.find((f: ServiceMetricDetailExplanationItem) => f.key == key);
  }

  getTitle() {
    if(!this.attributes.title) return '';

    let items = this.attributes.title.split(':');
    let title = items[1] || items[0] || '';
    title = title.replace('Total','');
    title = title.replace('Overview','');
    title = title.replace(' Count','');
    if(title.toLowerCase() == 'managed users'){ title = 'Active Users'; }
    if(title.toLowerCase() == 'managed endpoints'){ title = 'Total Endpoints'; }

    return title.trim();
  }

  static getTitleFromString(str: string): string {
    if(!str) return '';

    let items = str.split(':');
    let title = items[1] || items[0] || '';
    title = title.replace('Total','');
    title = title.replace('Overview','');
    title = title.replace(' Count','');
    if(title.toLowerCase() == 'managed users'){ title = 'Active Users'; }
    if(title.toLowerCase() == 'managed endpoints'){ title = 'Total Endpoints'; }

    return title.trim();
  }
}

export default ServiceMetric;
