<nz-layout class="tickets-layout ticket-new">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'support']">Support</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>New</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>

  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','support']" class="back-button"><i nz-icon nzType="left" [nzTheme]="'outline'"></i><span class="txt-grey"> Back</span></span>
      </div>
    </div>

    <div class="form-header">
      <h1>{{ flow?.attributes?.title }}</h1>
      <h3>Fill out the issue form and we’ll get back to you as soon as possible!</h3>
      <nz-alert nzType="error" nzMessage="Ticket creation to remote platforms is currently under construction." *ngIf="(!allowSubmitTo3rdPartyPlatform) && flow?.attributes?.platform_name != 'Deskware'"></nz-alert>
    </div>

    <nz-spin nzSimple *ngIf="loading"></nz-spin>

    @if (ticketCreateForm && formControls && formControls.length > 0 && !loading) {
      <form class="emp-form-wrapper"
            nz-form
            nzLayout="vertical"
            [formGroup]="ticketCreateForm"
            (ngSubmit)="onSubmit()">
        <div class="box">
          <nz-form-item *ngIf="(flow?.attributes?.subject == '' || flow?.attributes?.subject == null) && !flowHasSubjectControl" class="emp-form-form-item">
            <nz-form-label [nzRequired]="true">
              Subject
            </nz-form-label>
            <nz-form-control>
              <input nz-input
                    id="subject"
                    formControlName="subject"
                    data-lpignore="true"
                    placeholder="Type a brief description of your issue here" />
            </nz-form-control>
          </nz-form-item>

          <ng-container *ngFor="let control of formControls">
            <nz-form-item class="emp-form-form-item" *ngIf="control.attributes.input_type != 'hidden'">
              <nz-form-label [nzFor]="control.attributes.name" [nzRequired]="control.attributes.required ? true : false">
                {{ control.attributes.label }}
              </nz-form-label>

              <nz-form-control>
                <!-- Select control -->
                <nz-select *ngIf="control.attributes.input_type == 'select'"
                          [id]="control.attributes.name"
                          [nzPlaceHolder]="control.attributes.placeholder || ''"
                          [formControlName]="control.attributes.name">
                  <nz-option *ngFor="let option of control.attributes.options"
                            [nzValue]="option.value"
                            [nzLabel]="option.label"></nz-option>
                </nz-select>

                <!-- Input control -->
                <input nz-input [id]="control.attributes.name" [placeholder]="control.attributes.placeholder || ''" [formControlName]="control.attributes.name"  *ngIf="control.attributes.input_type == 'input'" data-lpignore="true" />

                <!-- Date control -->
                <nz-date-picker [id]="control.attributes.name" [formControlName]="control.attributes.name" *ngIf="control.attributes.input_type == 'date'" nzFormat="MM-dd-yyyy"></nz-date-picker>

                <!-- Textarea control -->
                <textarea rows="4" nz-input [id]="control.attributes.name" [placeholder]="control.attributes.placeholder || ''" [formControlName]="control.attributes.name" *ngIf="control.attributes.input_type == 'textarea'" data-lpignore="true"></textarea>

                <!-- Wysiwyg control -->
                <froala-editor *ngIf="control.attributes.input_type == 'wysiwyg'" [id]="control.attributes.name" [formControlName]="control.attributes.name"></froala-editor>

                <!-- Boolean control -->
                <nz-switch *ngIf="control.attributes.input_type == 'boolean'" [formControlName]="control.attributes.name"></nz-switch>

                <!-- Checkbox control -->
                <label *ngIf="control.attributes.input_type == 'checkbox'"
                      nz-checkbox
                      [formControlName]="control.attributes.name">
                  Checkbox
                </label>

                <!-- Radio control -->
                <nz-radio-group *ngIf="control.attributes.input_type == 'radio'"
                                [formControlName]="control.attributes.name"
                                nzButtonStyle="solid">
                  <label nz-radio [nzValue]="option.value" *ngFor="let option of control.attributes.options">{{ option.label }}</label>
                </nz-radio-group>

              </nz-form-control>
            </nz-form-item>
          </ng-container>
        </div>

        <div class="actions-container">
          <button nz-button
                  [disabled]="(!allowSubmitTo3rdPartyPlatform) && flow?.attributes?.platform_name != 'Deskware'"
                  type="submit"
                  nzType="primary"
                  nzShape="round"
                  nzSize="large">
            Create
          </button>
        </div>
      </form>
    }

    <custom-loader *ngIf="loaderVisible"></custom-loader>
  </nz-content>
</nz-layout>
