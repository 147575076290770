import { Component, computed, DestroyRef, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, debounceTime, distinctUntilChanged, skip } from 'rxjs';
import { UsersService } from 'src/app/services/users.service';
import { ReportParam } from 'src/app/utilities/models/parameters/reportParam/reportParam';
import { Permission } from 'src/app/utilities/models/permissions/permission';
import { User } from 'src/app/utilities/models/user/user';

interface INzTableSortOrder {
  order: string | null;
  attribute: string | null;
}

@Component({
    selector: 'app-users',
    templateUrl: './people-list.component.html',
    styleUrl: './people-list.component.scss',
    standalone: false
})
export class PeopleListComponent implements OnInit {
  filterActive: boolean = true;
  isLoading: boolean = false;
  isSortable = signal<boolean>(true);
  loggedInUser: User;
  pageIndex: number = 1;
  pageSize: number = 20;
  searchString: string = '';
  searchSubject$ = new BehaviorSubject<string>('');
  sort: INzTableSortOrder = {
    order: 'ascend',
    attribute: 'firstname'
  };
  total: number = 0;
  users: User[] = [];
  userCreatePermission: Permission | undefined;
  userShowPermission = signal<Permission | undefined>(undefined);

  constructor(private destroyRef: DestroyRef, private userService: UsersService) {}

  canViewUser = computed(() => {
    const userRole = this.loggedInUser?.relationships?.role?.attributes?.system_key ?? '';
    let canViewUser = !!(this.userShowPermission());

    if (['billing_contact_role'].includes(userRole)) canViewUser = false;

    return canViewUser;
  });

  ngOnInit() {
    this.loggedInUser = this.userService.loggedInUser;
    this.userCreatePermission = this.userService.findPermission(
      'Digcore::Contact',
      'ticketing/operator/v1/contacts',
      'create'
    );
    this.userShowPermission.set(this.userService.findPermission(
      'Digcore::User',
      'ticketing/operator/v1/users',
      'show'
    ));

    this.getPeople();
    this.subscribeToSearchString();
  }

  getPeople() {
    const sortOrder = (this.sort.order === 'ascend') ? 'asc' : 'desc';
    let reportParams: ReportParam[] = [
      { key: 'active', operator: ['eq'], value: this.filterActive },
      { key: 'type', operator: ['in'], value: ['Digcore::AccountAgent', 'Digcore::Contact'] },
    ];

    const query = this.searchString.trim();
    if (query.length > 2) {
      reportParams.push({ key: 'fullname', operator: ['like'], value: encodeURIComponent(query) });
      this.isSortable.set(false);
    } else {
      this.isSortable.set(true);
    }

    this.isLoading = true;
    this.userService.getAccountUsers({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      reportParams,
      sort: {
        attribute: this.sort?.attribute ?? undefined,
        order: sortOrder,
      },
    })
    .subscribe({
      next: (response: any) => {
        this.isLoading = false;
        this.users = response?.data?.map((item: any) => new User(item, response?.included ?? [])) ?? [];
        this.total = response?.meta?.total_count ?? this.users.length;
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      },
    });
  }

  onPageIndexChange(index: number) {
    this.pageIndex = index;
    this.getPeople();
  }

  onPageSizeChange(size: number) {
    this.pageSize = size;
    this.getPeople();
  }

  onSearchPeople() {
    const query = this.searchString.trim();

    if (query.length == 0 || query.length > 2) {
      this.searchSubject$.next(query);
    }
  }

  onSortOrderChange(order: string | null, attribute: string) {
    this.sort.order = order ?? 'ascend';
    this.sort.attribute = attribute;
    this.getPeople();
  }

  private subscribeToSearchString() {
    this.searchSubject$
      .pipe(
        debounceTime(500),
        skip(1), // this is to avoid the initial double call from init method
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (query: string) => {
          if (query.length > 0 && query.length < 3) return;
          this.pageIndex = 1;
          this.getPeople();
        },
        error: (error) => {
          console.log(error);
        }
      })
  }
}
