import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
@Injectable({ providedIn: "root" })
export class SavedFiltersService {
  constructor(private http: HttpClient) {
  }

  getSavedFiltersMyList() {
    const requestUrl = environment.api_url + 'operator/v1/saved_filters/my_list?sort=position';

    return this.http.get(requestUrl);
  }

  getSavedFiltersCount() {
    const requestUrl = environment.api_url + 'operator/v1/saved_filters/count?page[per]=100';

    return this.http.get(requestUrl);
  }
}
